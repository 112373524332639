import React, { Component, } from 'react'
import Loader from 'components/Loader'
import _ from "lodash"
import './MerchantView.scss'
import MerchantHeader from '../MerchantHeader'
import FundingHoldBanner from '../FundingHoldBanner'
import StackOwnedBanner from '../StackOwnedBanner'
import CTABanner from '../CTABanner'
import MerchantTabs from '../MerchantTabs'
import MerchantStatus from '../MerchantStatus'
import MerchantMenu from '../MerchantMenu'
import AlertBanner from '../AlertBanner'

class MerchantView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      canViewMerchant: false,
      merchantStatus: null,
    }
  }

  componentDidMount() {
    this.props.fetchMerchantProfile(this.props.routeParams.id)
    this.props.fetchFullRatesList()
    this.props.fetchMerchantHash(this.props.routeParams.id)
    this.props.fetchMerchantAlerts(this.props.routeParams.id)
    if (!this.props.adminRoles) {
      this.props.fetchAdminRoles()
    } else {
      this.setState({
        canViewMerchant:
          Object.keys(this.props.adminRoles).includes('can-view-merchant-info'),
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.adminRoles && this.props.adminRoles !== prevProps.adminRoles) {
      this.setState({
        canViewMerchant:
          Object.keys(this.props.adminRoles).includes('can-view-merchant-info'),
      })
    }
    if (!_.isEmpty(this.props.merchantProfile) && this.props.merchantProfile !== prevProps.merchantProfile) {
      this.setState({ merchantStatus: this.props.merchantProfile.status })
    }
    if ((this.props.accountUpdated && !prevProps.accountUpdated)) {
      setImmediate(() => {
        this.props.showSuccess('Merchant updated', 'The merchant account has been updated')
      })
      this.props.fetchMerchantProfile(this.props.routeParams.id)
      this.props.fetchFullRatesList()
      this.props.fetchMerchantHash(this.props.routeParams.id)
      this.props.fetchMerchantAlerts(this.props.routeParams.id)
    }
  }

  _onUpdateStatus = (status, statusCode, reason = null) => {
    this.props.updateMerchantStatus(this.props.routeParams.id, status, reason)
    this.setState({ merchantStatus: statusCode })
  }

  _onSendBeneficialData = (data) => {
    this.props.sendBeneficialData(this.props.routeParams.id, data)
  }

  _submitUpdateFundingHold = (data) => {
    this.props.updateFundingHold(this.props.routeParams.id, data)
  }

  _submitRevertProcessor = (data) => {
    this.props.revertProcessor(this.props.routeParams.id, data)
  }

  _submitTOSAcceptance = () => {
    this.props.acceptTOS(this.props.routeParams.id)
  }

  _onUpdateMerchant = () => {
    this.props.fetchMerchantProfile(this.props.routeParams.id)
    this.props.fetchFullRatesList()
    this.props.fetchMerchantHash(this.props.routeParams.id)
    this.props.fetchMerchantAlerts(this.props.routeParams.id)
  }

  render() {
    const {
      isFetching,
      merchantProfile,
      fullRatesList,
      merchantHashValue,
      children,
      adminRoles,
      merchantAlerts
    } = this.props
    const {
      canViewMerchant,
      merchantStatus,
    } = this.state

    const activeTab = this.props.location.pathname.split('/').pop()

    return (
      <div className="merchant-profile-view">
        {isFetching ?
          <Loader /> :
          <div className="container-fluid">
            {!canViewMerchant ?
              <div>Not Allowed</div> :
              <div className="merchant-profile-view-inner-container">
                <MerchantHeader merchant={merchantProfile} />
                <StackOwnedBanner merchant={merchantProfile} />
                <AlertBanner merchantAlerts={merchantAlerts} />
                <FundingHoldBanner merchant={merchantProfile} />
                <CTABanner merchant={merchantProfile} />
                <div className={`content active-tab-${activeTab}`}>
                  <div className="merchant-content-header">
                    <MerchantTabs merchant={merchantProfile} adminRoles={this.props.adminRoles} />
                    {(merchantStatus || merchantStatus === 0) &&
                      <MerchantStatus merchant={merchantProfile} merchantStatus={merchantStatus} />}
                    <MerchantMenu
                      merchant={merchantProfile}
                      adminRoles={adminRoles}
                      rates={fullRatesList}
                      merchantStatus={merchantStatus}
                      onUpdateStatus={(status, statusCode, reason = null) => {
                        this._onUpdateStatus(status, statusCode, reason)
                      }}
                      onSendBeneficialData={(data) => {
                        this._onSendBeneficialData(data)
                      }}
                      beneficialData={this.props.beneficialData}
                      merchantHash={merchantHashValue}
                      error={this.props.error}
                      isSubmitting={this.props.isSubmitting}
                      submitUpdateFundingHold={(data) => { this._submitUpdateFundingHold(data) }}
                      submitRevertProcessor={(data) => { this._submitRevertProcessor(data) }}
                      submitTOSAcceptance={() => { this._submitTOSAcceptance() }}
                    />
                  </div>
                  {React.cloneElement(children, {
                    isFetching,
                    merchant: merchantProfile,
                    merchantAlerts,
                    onUpdateMerchant: this._onUpdateMerchant
                  })}
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export default MerchantView
