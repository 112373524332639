import { call, } from 'redux-saga/effects'
import moment from 'moment'

const sortStrBuilder = (sort) => {
  let sortStr = ''
  const keys = Object.keys(sort)
  keys.forEach((key) => {
    sortStr += sort[key] === 'DESC' ? `-${key}` : `${key}`
    if (keys.indexOf(key) !== keys.length - 1) sortStr += ','
  })

  return sortStr
}

/**
 * Expose all endponts for 'reports'.
 */
const ReportsApi = (api) => ({
  * fetchMerchantSummary({ accountId, }) {
    return yield call(api.get, `/merchants/${accountId}/summary`)
  },
  * fetchMerchantInternalSummary({ accountId, }) {
    return yield call(api.get, `/merchants/${accountId}/summary/internal`)
  },
  * fetchMerchantUsers({ merchantId, profileId, pagination = { page: 1, perPage: 10, }, sort, search }) {
    const params = {
      page: pagination.page,
      per_page: pagination.perPage,
      sort: sortStrBuilder(sort),
      search,
      profileId
    }

    return yield call(api.get, `/merchants/${merchantId}/users`, { params, })
  },

  * downloadUserList({ merchantId, }) {
    const params = {
      merchant_id: merchantId,
      download: true,
    }
    return yield call(api.get, `/merchants/${merchantId}/users/download`, { params, })
  },

  * fetchUserNotificationSettings({ merchantId, email }) {
    const params = {
      merchantId,
      email
    }

    return yield call(api.get, `/merchants/${merchantId}/users/notifications`, { params, })
  },

  * updateNotificationSettings({ merchantId, email, setting, value }) {
    const params = {
      merchantId,
      email,
      setting,
      value
    }
    return yield call(api.post, `/merchants/${merchantId}/users/notifications`, { params, })
  },

  * fetchChargebackCountOverview(mid, startDate, endDate) {
    const endpoint = `/merchant-overview/${mid}/chargeback-count`
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    }

    return yield call(api.get, endpoint, { params, })
  },

  * fetchEvidenceNeededChargebackCountOverview(mid, startDate, endDate) {
    const endpoint = `/merchant-overview/${mid}/evidence-chargeback-count`
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    }

    return yield call(api.get, endpoint, { params, })
  },
  * fetchChargebackVolumeOverview(mid, startDate, endDate) {
    const endpoint = `/merchant-overview/${mid}/chargeback-volume`
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    }

    return yield call(api.get, endpoint, { params, })
  },
  * fetchGrossVolumeOverview(mid, startDate, endDate) {
    const endpoint = `/merchant-overview/${mid}/gross-volume`
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    }

    return yield call(api.get, endpoint, { params, })
  },
  * fetchSuccessfulTransactionsOverview(mid, startDate, endDate) {
    const endpoint = `/merchant-overview/${mid}/successful-transactions`
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    }

    return yield call(api.get, endpoint, { params, })
  },
  * fetchAverageTransactionOverview(mid, startDate, endDate) {
    const endpoint = `/merchant-overview/${mid}/average-transaction`
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    }

    return yield call(api.get, endpoint, { params, })
  },

  * fetchPayoutVolumeOverview(mid, startDate, endDate) {
    const endpoint = `/merchant-overview/${mid}/payout-volume`
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    }

    return yield call(api.get, endpoint, { params, })
  },

  * fetchTransactionSummary({ accountId, filters, }) {
    return yield call(api.get, `/merchants/${accountId}/transactions/summary`,
      {
        params: {
          start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        },
      }
    )
  },

  * fetchTransactionPaymentLinks({ accountId, }) {
    return yield call(api.get, `/checkout/${accountId}/transaction-payment-links`, {
    })
  },

  * fetchTransactionReport({ accountId, transactionState, searchValue, filters, pagination = { page: 1, perPage: 25, }, sort = { transDate: 'DESC', }, }) {
    let tzFromOffset = new Date(filters.from_date).getTimezoneOffset() / 60
    let tzToOffset = new Date(filters.to_date).getTimezoneOffset() / 60

    // try to figure out the offset if we're crossing DST on/off hours
    // start date crosses spring forward
    if (
      moment(filters.from_date).isDST() === false &&
      moment(filters.from_date).add(tzFromOffset, 'hours').isDST() === true
    ) {
      tzFromOffset -= 1
    }
    // start date crosses fall back
    if (
      moment(filters.from_date).isDST() === true &&
      moment(filters.from_date).add(tzFromOffset, 'hours').isDST() === false
    ) {
      tzFromOffset += 1
    }

    // end date crosses spring forward
    if (
      moment(filters.to_date).isDST() === false &&
      moment(filters.to_date).add(tzToOffset, 'hours').isDST() === true
    ) {
      tzToOffset -= 1
    }
    // end date crosses fall back
    if (
      moment(filters.to_date).isDST() === true &&
      moment(filters.to_date).add(tzToOffset, 'hours').isDST() === false
    ) {
      tzToOffset += 1
    }

    return yield call(api.get, `/merchants/${accountId}/transactions`,
      {
        params: {
          start_date: moment(filters.from_date).add(tzFromOffset, 'hours').format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(filters.to_date).add(tzToOffset, 'hours').format('YYYY-MM-DD HH:mm:ss'),
          date_type: filters.date_type,
          page: pagination.page,
          per_page: pagination.perPage,
          sort: sortStrBuilder(sort),
          filters: filters.checkboxFilters,
          search: searchValue,
          payment_link_id: filters.payment_link_id
        },
      }
    )
  },

  * fetchAdminTransactionReport({ accountId, transactionState, searchValue, filters, pagination = { page: 1, perPage: 25, }, sort = { transDate: 'DESC', }, }) {
    let tzFromOffset = new Date(filters.from_date).getTimezoneOffset() / 60
    let tzToOffset = new Date(filters.to_date).getTimezoneOffset() / 60

    // try to figure out the offset if we're crossing DST on/off hours
    // start date crosses spring forward
    if (
      moment(filters.from_date).isDST() === false &&
      moment(filters.from_date).add(tzFromOffset, 'hours').isDST() === true
    ) {
      tzFromOffset -= 1
    }
    // start date crosses fall back
    if (
      moment(filters.from_date).isDST() === true &&
      moment(filters.from_date).add(tzFromOffset, 'hours').isDST() === false
    ) {
      tzFromOffset += 1
    }

    // end date crosses spring forward
    if (
      moment(filters.to_date).isDST() === false &&
      moment(filters.to_date).add(tzToOffset, 'hours').isDST() === true
    ) {
      tzToOffset -= 1
    }
    // end date crosses fall back
    if (
      moment(filters.to_date).isDST() === true &&
      moment(filters.to_date).add(tzToOffset, 'hours').isDST() === false
    ) {
      tzToOffset += 1
    }

    return yield call(api.get, `/merchants/${accountId}/admin-transactions`,
      {
        params: {
          start_date: moment(filters.from_date).add(tzFromOffset, 'hours').format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(filters.to_date).add(tzToOffset, 'hours').format('YYYY-MM-DD HH:mm:ss'),
          date_type: filters.date_type,
          page: pagination.page,
          per_page: pagination.perPage,
          sort: sortStrBuilder(sort),
          filters: filters.checkboxFilters,
          search: searchValue,
        },
      }
    )
  },

  * fetchIndividualTransaction({ transactionId, accountId, }) {
    return yield call(api.get, `/transactions/${transactionId}`, { params: { merchant_id: accountId, }, })
  },
  * fetchDispute({ disputeId, disputeType, }) {
    return yield call(api.get, `/disputes/${disputeId}/${disputeType}`, { params: { }, })
  },
  * fetchEvidenceCategories() {
    return yield call(api.get, `/disputes/evidence-category-options`, { params: { }, })
  },
  * uploadFile({ file, type = 'dispute_evidence', }) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', type)

    return yield call(api.post, `/files/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  * removeFile({ fileId, }) {
    return yield call(api.delete, `/files/${fileId}`, { params: { }, })
  },

  * fetchDisputeEvidenceFile({ fileId, }) {
    return yield call(api.get, `/files/${fileId}`, {
      responseType: 'arraybuffer'
    })
  },

  * submitDisputeCounter({ disputeId, data, }) {
    return yield call(api.post, `/disputes/${disputeId}/chargeback/counter`, data)
  },

  * submitDisputeAccept({ disputeId, }) {
    return yield call(api.post, `/disputes/${disputeId}/chargeback/accept`)
  },

  * updateDisputeStatus({ disputeId, newStatus, }) {
    return yield call(api.post, `/disputes/${disputeId}/chargeback/status`, { status: newStatus, })
  },

  * fetchDisputeEvidence({ disputeId, }) {
    return yield call(api.get, `/disputes/${disputeId}/chargeback/evidence`)
  },

  * fetchTransactionSearch({ searchTerm, accountId, filters, }) {
    return yield call(api.get, `/transactions`, {
      params: {
        search: searchTerm,
        merchant_id: accountId,
        start_date: moment(filters.from_date).format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.to_date).format('YYYY-MM-DD HH:mm:ss'),
      },
    })
  },

  * fetchTransactionSnapshot({ accountId, filters, }) {
    return yield call(api.get, `/merchants/${accountId}/transactions/snapshot`,
      {
        params: {
          start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        },
      }
    )
  },

  * downloadTransactions({ accountId, filters, sort }) {
    return yield call(api.get, `/merchants/${accountId}/transactions`, {
      params: {
        start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        sort: sortStrBuilder(sort),
        download: true,
        date_type: filters.date_type,
        filters: filters.checkboxFilters,
        search: filters.search,
        columns: filters.columns,
        preFormattedColNames: 'true',
        payment_link_id: filters.payment_link_id
      },
    })
  },

  * downloadAdminTransactions({ accountId, filters, sort }) {
    return yield call(api.get, `/merchants/${accountId}/admin-transactions`, {
      params: {
        start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        sort: sortStrBuilder(sort),
        download: true,
        date_type: filters.date_type,
        filters: filters.checkboxFilters,
        search: filters.search,
      },
    })
  },

  * fetchCtaReport({ ctaId }) {
    return yield call(api.get, `/admin/cta/${ctaId}/report`, {})
  },

  * fetchCtaCompletedReport({ ctaId }) {
    return yield call(api.get, `/admin/cta/${ctaId}/completed-report`, {})
  },

  * fetchCtaAssignedReport({ ctaId }) {
    return yield call(api.get, `/admin/cta/${ctaId}/assigned-report`, {})
  },

  * downloadSnapshot({ accountId, filters, }) {
    return yield call(api.get, `/merchants/${accountId}/transactions/snapshot`, {
      params: {
        start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        download: true,
        date_type: filters.date_type || 'funded_at',
      },
    })
  },

  * fetchChargebackSummary({ accountId, }) {
    return yield call(api.get, `/merchants/${accountId}/chargebacks/summary`)
  },

  * fetchChargebackOverview({ accountId, filters, }) {
    const params = {
      filters: filters.multiSelectFilters
    }

    if (filters.from_date) {
      params.start_date = moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss')
    }
    if (filters.to_date) {
      params.end_date = moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss')
    }
    return yield call(api.get, `/merchants/${accountId}/chargebacks/overview`, { params })
  },

  * fetchChargebackDetails({ accountId, filters, pagination = { page: 1, perPage: 10, }, sort, }) {
    const params = {
      filters: filters.multiSelectFilters,
      dispute_status: filters.disputeStatus,
      page: pagination.page,
      per_page: pagination.perPage,
      sort: sortStrBuilder(sort),
    }

    if (filters.from_date) {
      params.start_date = moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss')
    }
    if (filters.to_date) {
      params.end_date = moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss')
    }

    return yield call(api.get, `/merchants/${accountId}/chargebacks`, { params })
  },

  * downloadChargebacks({ accountId, filters, sort, }) {
    return yield call(api.get, `/merchants/${accountId}/disputes-download`, {
      params: {
        start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        sort: sortStrBuilder(sort),
        filters: filters.multiSelectFilters,
        dispute_status: filters.disputeStatus,
        download: true,
      },
    })
  },

  * downloadMerchDisputes({ accountId, filters, sort, }) {
    return yield call(api.get, `/merchants/${accountId}/dispute-grid`, {
      params: {
        start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        sort: sortStrBuilder(sort),
        filters: filters.multiSelectFilters,
        dispute_status: filters.disputeStatus,
        download: true,
      },
    })
  },

  * downloadRetrievals({ accountId, filters, sort, }) {
    return yield call(api.get, `/merchants/${accountId}/retrievals`, {
      params: {
        start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        sort: sortStrBuilder(sort),
        download: true,
      },
    })
  },

  * fetchRetrievalDetails({ accountId, filters, pagination = { page: 1, perPage: 10, }, sort = { transDate: 'DESC', }, }) {
    return yield call(api.get, `/merchants/${accountId}/retrievals`, {
      params: {
        start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        page: pagination.page,
        per_page: pagination.perPage,
        sort: sortStrBuilder(sort),
      },
    })
  },

  * fetchACHReturnSummary({ accountId, }) {
    return yield call(api.get, `/merchants/${accountId}/ach-returns/summary`)
  },

  * fetchACHReturnOverview({ accountId, filters, }) {
    return yield call(api.get, `/merchants/${accountId}/ach-returns/overview`, {
      params: {
        start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      },
    })
  },

  * fetchACHReturnDetails({ accountId, filters, pagination = { page: 1, perPage: 10, }, sorts, }) {
    return yield call(api.get, `/merchants/${accountId}/ach-returns`, {
      params: {
        start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        filters: filters.multiSelectFilters,
        page: pagination.page,
        sort: sortStrBuilder(sorts),
        per_page: pagination.perPage,
      },
    })
  },

  * downloadACHReturns({ accountId, filters, sorts, }) {
    return yield call(api.get, `/merchants/${accountId}/ach-returns`, {
      params: {
        start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        download: true,
        sort: sortStrBuilder(sorts),
      },
    })
  },

  * fetchProcessorReconciliationReport({ filters, pagination = { page: 1, perPage: 25, }, sort, }) {
    const params = {
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      page: pagination.page,
      per_page: pagination.perPage,
      sort: sortStrBuilder(sort),
    }

    if (filters.depositId && filters.depositId.length > 0) params.deposit_id = filters.depositId

    return yield call(api.get, `/admin/merchants/processor-reconciliation`, { params, })
  },

  * downloadProcessorReconciliation({ filters, sort, }) {
    const params = {
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      sort: sortStrBuilder(sort),
      download: true,
    }

    if (filters.depositId && filters.depositId.length > 0) params.deposit_id = filters.depositId

    return yield call(api.get, `/admin/merchants/processor-reconciliation`, { params, })
  },

  * downloadProcessorReconciliationDetail({ filters }) {
    const params = {
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      sort: '-fundDate',
      download: true,
      detail: true,
    }

    if (filters.depositId && filters.depositId.length > 0) params.deposit_id = filters.depositId

    return yield call(api.get, `/admin/merchants/processor-reconciliation`, { params, })
  },

  * fetchSubscriptionReport({ accountId, filters, pagination = { page: 1, perPage: 10, }, sort, search }) {
    const params = {
      start_date: moment(filters.from_date).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).format('YYYY-MM-DD HH:mm:ss'),
      page: pagination.page,
      per_page: pagination.perPage,
      sort: sortStrBuilder(sort),
      search,
    }

    return yield call(api.get, `/merchants/${accountId}/subscription-report`, { params, })
  },

  * downloadSubscriptionList({ merchantId, filters }) {
    const params = {
      start_date: moment(filters.from_date).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).format('YYYY-MM-DD HH:mm:ss'),
      merchant_id: merchantId,
      download: true,
    }
    return yield call(api.get, `/merchants/${merchantId}/subscriptions/download`, { params, })
  },

  * fetchIndividualSubscription({ subscriptionId, accountId, }) {
    return yield call(api.get, `/products/subscriptions/${subscriptionId}`, { params: { merchant_id: accountId, }, })
  },

  * fetchCustomerReport({ accountId, filters, pagination = { page: 1, perPage: 10, }, sort, search }) {
    const params = {
      start_date: moment(filters.from_date).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).format('YYYY-MM-DD HH:mm:ss'),
      custom_sort: filters.custom_sort,
      page: pagination.page,
      per_page: pagination.perPage,
      sort: sortStrBuilder(sort),
      search,
    }

    return yield call(api.get, `/merchants/${accountId}/customer-report`, { params, })
  },

  * fetchCustomerReportSummary({ accountId }) {
    return yield call(api.get, `/merchants/${accountId}/customer-report-summary`)
  },

  * downloadCustomerList({ accountId, filters, }) {
    const params = {
      start_date: moment(filters.from_date).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).format('YYYY-MM-DD HH:mm:ss'),
      merchant_id: accountId,
      download: true,
      search: filters.search,
      custom_sort: filters.custom_sort,
    }
    return yield call(api.get, `/merchants/${accountId}/customers/download`, { params, })
  },

  * fetchIndividualCustomer({ customerId, accountId, }) {
    return yield call(api.get, `/customers/${customerId}`, { params: { merchant_id: accountId, }, })
  },

  * fetchCustomerAverageTransactionOverview({ customerId, accountId, startDate, endDate }) {
    const endpoint = `/customers/${customerId}/average-customer-spend`;
    const params = {
      merchant_id: accountId,
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    };

    return yield call(api.get, endpoint, { params });
  },

  * fetchReconciliationReport({ accountId, filters, pagination = { page: 1, perPage: 10, }, sort, search }) {
    const params = {
      start_date: moment(filters.from_date).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).format('YYYY-MM-DD HH:mm:ss'),
      page: pagination.page,
      per_page: pagination.perPage,
      sort: sortStrBuilder(sort),
      filters: filters.statusFilters,
    }

    if (filters.batchId && filters.batchId.length > 0) params.batch_id = filters.batchId

    return yield call(api.get, `/merchants/${accountId}/deposits`, { params, })
  },

  * downloadReconciliation({ accountId, filters, sort, }) {
    const params = {
      start_date: moment(filters.from_date).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).format('YYYY-MM-DD HH:mm:ss'),
      sort: sortStrBuilder(sort),
      filters: filters.statusFilters,
      download: true,
    }

    if (filters.batchId && filters.batchId.length > 0) params.batch_id = filters.batchId

    return yield call(api.get, `/merchants/${accountId}/deposits/summary/download`, { params, })
  },

  * downloadAdminReconciliation({ accountId, filters, sort, }) {
    const params = {
      start_date: moment(filters.from_date).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).format('YYYY-MM-DD HH:mm:ss'),
      sort: sortStrBuilder(sort),
      download: true,
    }

    if (filters.batchId && filters.batchId.length > 0) params.batch_id = filters.batchId

    return yield call(api.get, `/merchants/${accountId}/admin-deposits`, { params, })
  },

  * fetchReconciliationReportDetail({ batchId, merchantId, }) {
    return yield call(api.get, `/deposits/${batchId}?merchant_id=${merchantId}&sort=-fundDate`)
  },

  * refundTransaction({ transactionId, merchantId, amount, }) {
    return yield call(api.put, `/transactions/${transactionId}/refund`, { amount, merchantId, })
  },

  * voidTransaction({ transactionId, merchantId, }) {
    return yield call(api.put, `/transactions/${transactionId}/void`, { merchantId, })
  },

  * downloadReconciliationDetail({ accountId, batchId, }) {
    const params = {
      merchant_id: accountId,
      sort: '-fundDate',
      download: true,
    }

    return yield call(api.get, `/deposits/${batchId}/download`, { params, })
  },

  * depositDetail({ accountId, depositId, }) {
    const params = {
      merchant_id: accountId,
    }

    return yield call(api.get, `/deposits/${depositId}`, { params })
  },

  * fetchVolumeOverviewByClient({ clientId, processorId }) {
    if (clientId) {
      return yield call(api.get, `/admin/clients/${clientId}/volume-overview`, {
        params: {
          processorId,
        }
      })
    }
    return yield call(api.get, `/admin/clients/volume-overview`, {
      params: {
        processorId,
      }
    })
  },

  * fetchVolumeReportByClient({ clientId = null, processorId }) {
    if (clientId) {
      return yield call(api.get, `/admin/clients/${clientId}/volume-report`, {
        params: {
          processorId,
        }
      })
    }
    return yield call(api.get, `/admin/clients/volume-report`, {
      params: {
        processorId,
      }
    })
  },

  * fetchVolumeReportMonthDownload({ clientId = null, processorId, countryCode, reportType }) {
    if (clientId) {
      return yield call(api.get,
        `/admin/clients/past-16-months-volume-report/${clientId}/${countryCode}/${reportType}`, {
          params: {
            processorId,
          }
        })
    }

    return yield call(api.get, `/admin/clients/past-16-months-volume-report/${countryCode}/${reportType}`, {
      params: {
        processorId,
      }
    })
  },

  * fetchAccountMonthDownload({ clientId = null, processorId, countryCode }) {
    if (clientId) {
      return yield call(api.get,
        `/admin/clients/past-months-accounts-report/${clientId}/${countryCode}`, {
          params: {
            processorId,
          }
        })
    }

    return yield call(api.get, `/admin/clients/past-months-accounts-report/${countryCode}`, {
      params: {
        processorId,
      }
    })
  },

  * fetchVolumeOverviewWithoutClient({ processorId }) {
    return yield call(api.get, `/admin/clients/volume-overview`, {
      params: {
        processorId,
      }
    })
  },


  * downloadPendingAccounts() {
    return yield call(api.get, `/admin/merchants/pending-accounts`)
  },
  * fetchMerchantClientInfo({ merchantId }) {
    return yield call(api.get, `/merchants/${merchantId}/client`)
  },

  * fetchPaymentInfoByToken({ token, merchantId, amount }) {
    return yield call(api.get, `/transactions/payment-info`, {
      params: {
        token,
        merchantId,
        amount
      }
    })
  },

  * submitCreateTransaction({ transactionInfo }) {
    return yield call(api.post, `/transactions`, transactionInfo)
  },

  * fetchCheckoutDashboard(mid, startDate, endDate) {
    const endpoint = `/checkout/${mid}/dashboard`
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    }

    return yield call(api.get, endpoint, { params, })
  },

  * fetchCheckoutSettings(mid) {
    const endpoint = `/checkout/${mid}`

    return yield call(api.get, endpoint)
  },

  * updateCheckoutSettings(mid, data) {
    const endpoint = `/checkout/${mid}`

    return yield call(api.patch, endpoint, data)
  },

  * fetchPaymentLinks({ accountId, filters, pagination = { page: 1, perPage: 10, }, sort = { createdAt: 'DESC', }, }) {
    return yield call(api.get, `/checkout/${accountId}/payment-links`, {
      params: {
        start_date: moment(filters.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        filters: [
          filters.statusFilterValue
        ],
        search: filters.search,
        page: pagination.page,
        per_page: pagination.perPage,
        sort: sortStrBuilder(sort),
      },
    })
  },

  * fetchPaymentLinkDetailsGeneral({ paymentLinkToken }) {
    return yield call(api.get, `/checkout/payment-link/${paymentLinkToken}/general`)
  },
  * fetchPaymentLinkDetailsViews({ paymentLinkToken, filters }) {
    const params = {
      end_date: moment(filters.endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    }
    if (filters.startDate) params.start_date = moment(filters.startDate).utc().format('YYYY-MM-DD HH:mm:ss')
    return yield call(api.get, `/checkout/payment-link/${paymentLinkToken}/views`, {
      params
    })
  },
  * fetchPaymentLinkDetailsSales({ paymentLinkToken, filters }) {
    const params = {
      end_date: moment(filters.endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    }
    if (filters.startDate) params.start_date = moment(filters.startDate).utc().format('YYYY-MM-DD HH:mm:ss')
    return yield call(api.get, `/checkout/payment-link/${paymentLinkToken}/sales`, {
      params
    })
  },
  * fetchPaymentLinkDetailsGross({ paymentLinkToken, filters }) {
    const params = {
      end_date: moment(filters.endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
    }
    if (filters.startDate) params.start_date = moment(filters.startDate).utc().format('YYYY-MM-DD HH:mm:ss')
    return yield call(api.get, `/checkout/payment-link/${paymentLinkToken}/gross`, {
      params
    })
  },

  * createPaymentLink({ params }) {
    return yield call(api.post, `/checkout/${params.Merchant}/payment-links`, params)
  },

  * editPaymentLink({ params }) {
    return yield call(api.patch, `/checkout/${params.Merchant}/payment-links/${params.Token}`, params)
  },

  * deactivatePaymentLink({ params }) {
    return yield call(api.patch, `/checkout/${params.Merchant}/payment-links/${params.Token}/deactivate`, params)
  },

  * fetchPaymentLinksDownload({ accountId, filters, sort, }) {
    return yield call(api.get, `/checkout/${accountId}/payment-links`, {
      params: {
        start_date: moment(filters.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(filters.endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        filters: [
          filters.statusFilterValue
        ],
        search: filters.search,
        sort: sortStrBuilder(sort),
        download: true,
      },
    })
  }
})

export default ReportsApi
