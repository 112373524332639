import { connect, } from 'react-redux'
import NotificationActionCreators from 'ducks/Notifications/actions'
import MerchantActionCreators from 'ducks/Merchant/actions'
import AccountActionCreators from 'ducks/Account/actions'
import MerchantView from '../../pages/AdminPortal/pages/Merchant/components/MerchantView'

const mapStateToProps = ({ merchant, account }) => ({
  isFetching: merchant.isFetching,
  isSubmitting: merchant.isSubmitting,
  merchantProfile: merchant.merchantProfile,
  adminRoles: account.roles,
  fullRatesList: merchant.fullRatesList,
  isFetchingRatesList: merchant.isFetchingRatesList,
  accountUpdated: merchant.accountUpdated,
  showSuccessModal: merchant.showSuccessModal,
  isFetchingMerchantAlerts: merchant.isFetchingMerchantAlerts,
  merchantAlerts: merchant.merchantAlerts,
  beneficialData: merchant.beneficialData,
  merchantHashValue: merchant.merchantHash,
  error: merchant.error
})

const mapDispatchToProps = (dispatch) => ({
  fetchMerchantProfile: (merchantId) => dispatch(MerchantActionCreators.fetchMerchantProfile(merchantId)),
  fetchMerchantAlerts: (merchantId) => dispatch(MerchantActionCreators.fetchMerchantAlerts(merchantId)),
  fetchAdminRoles: () => dispatch(AccountActionCreators.fetchAdminRoles()),
  fetchFullRatesList: () => dispatch(MerchantActionCreators.fetchFullRatesList()),
  showSuccess: (title, message) => dispatch(NotificationActionCreators.showSuccess(title, message)),
  updateMerchantStatus: (merchantId, merchantStatus, closureReason = null) =>
    dispatch(MerchantActionCreators.updateMerchantStatus(merchantId, merchantStatus, closureReason)),
  updateFundingHold: (merchantId, data) => dispatch(MerchantActionCreators.updateFundingHold(merchantId, data)),
  sendBeneficialData: (merchantId, data) => dispatch(MerchantActionCreators.sendBeneficialData(merchantId, data)),
  fetchMerchantHash: (merchantId) => dispatch(MerchantActionCreators.fetchMerchantHash(merchantId)),
  revertProcessor: (merchantId, data) =>
    dispatch(MerchantActionCreators.revertProcessor(merchantId, data)
    ),
  acceptTOS: (merchantId) => dispatch(MerchantActionCreators.acceptTOS(merchantId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MerchantView)
