import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  DialogContent,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import './ViewMerchantHashModal.scss'

const ViewMerchantHashModal = ({ merchantHash, open, handleClose }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (open) {
      if (merchantHash.data) {
        setLoading(false)
      } else {
        setLoading(true)
      }
    } else {
      setLoading(false)
    }
  }, [open, merchantHash.data])

  return (
    <Dialog
      className='column-selection-dialog'
      maxWidth={'lg'}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '40px',
          padding: '40px',
          background: '#EBF0F5',
        },
      }}
      slotProps={{
        backdrop: {
          sx: { backgroundColor: 'rgba(0, 14, 77, 0.85)' }
        }
      }}
      aria-labelledby='column-selection-dialog-title'
    >
      <DialogTitle id='column-selection-dialog-title' className='column-selection-header'>
              Merchant Hash
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color='error'
          size='large'
          style={{
            position: 'absolute',
            right: 24,
            top: 24,
          }}
        >
          <CloseIcon fontSize='large'/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="view-merchant-hash-content-wrapper">
          <p className="description">
            <strong>Merchant Hash for MID {merchantHash.data.id}: </strong>
            {merchantHash.data.requestHashKey}
          </p>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ViewMerchantHashModal
