import React, { Component, } from 'react'
import { Link, withRouter } from 'react-router'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import _ from 'lodash'
import './MerchantMenu.scss'
import { MERCHANT_STATUSES } from '../../constants'
import ChangeStatusModal from '../ChangeStatusModal/ChangeStatusModal'
import SendBeneficialDataModal from '../SendBeneficialDataModal/SendBeneficialDataModal'
import ViewMerchantHashModal from '../ViewMerchantHashModal/ViewMerchantHashModal'
import FundingHoldModal from '../FundingHoldModal/FundingHoldModal'
import RevertProcessorModal from '../RevertProcessorModal/RevertProcessorModal'
import TOSAcceptModal from '../TOSAcceptModal/TOSAcceptModal'

class MerchantMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showMenuDropdown: null,
      changeStatusModalOpen: false,
      fundingHoldModalOpen: false,
      sendBeneficialDataModalOpen: false,
      revertProcessorModalOpen: false,
      acceptTOSModalOpen: false,
      canPlaceMerchantFundingHold: false,
      canReleaseMerchantFundingHold: false,
      canChangeMerchantStatus: false,
      canRevertProcessor: false,
      canEditMerchantAdvancedInfo: false,
      viewMerchantHashModalOpen: false,
      canViewMerchantHash: false,
    }
  }

  componentDidMount() {
    if (!this.props.adminRoles) {
      this.props.fetchAdminRoles()
    } else {
      this.setState({
        canPlaceMerchantFundingHold:
          Object.keys(this.props.adminRoles).includes('can-place-merchant-funding-hold'),
        canReleaseMerchantFundingHold:
          Object.keys(this.props.adminRoles).includes('can-release-merchant-funding-hold'),
        canChangeMerchantStatus: Object.keys(this.props.adminRoles).includes('can-change-merchant-status'),
        canRevertProcessor: Object.keys(this.props.adminRoles).includes('can-revert-processor'),
        canEditMerchantAdvancedInfo: Object.keys(this.props.adminRoles).includes('can-edit-merchant-advanced-info'),
        canViewMerchantHash: Object.keys(this.props.adminRoles).includes('can-view-merchant-hash'),

      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.adminRoles && this.props.adminRoles !== prevProps.adminRoles) {
      this.setState({
        canPlaceMerchantFundingHold:
          Object.keys(this.props.adminRoles).includes('can-place-merchant-funding-hold'),
        canReleaseMerchantFundingHold:
          Object.keys(this.props.adminRoles).includes('can-release-merchant-funding-hold'),
        canChangeMerchantStatus: Object.keys(this.props.adminRoles).includes('can-change-merchant-status'),
        canRevertProcessor: Object.keys(this.props.adminRoles).includes('can-revert-processor'),
        canEditMerchantAdvancedInfo: Object.keys(this.props.adminRoles).includes('can-edit-merchant-advanced-info'),
        canViewMerchantHash: Object.keys(this.props.adminRoles).includes('can-view-merchant-hash'),
      })
    }
  }

  _handleStatusChange = (payload) => {
    // This function will be called after the state has been updated
    if (payload.changedStatus === 1) {
      this.props.onUpdateStatus('enabled', 1)
    }
    if (payload.changedStatus === 66) {
      this.props.onUpdateStatus('incomplete', 66)
    }
    if (payload.changedStatus === 33) {
      this.props.onUpdateStatus('closed', 33, payload.closureReason)
      this.setState({ currentStatus: 33, })
    }
    this.setState({ fundingHoldModalOpen: false })
  }

  _handleSendBeneficialData = (payload) => {
    this.props.onSendBeneficialData(payload)
  }

  _showChangeStatusModal = () => {
    this.setState({
      changeStatusModalOpen: true,
      openModalData: {},
    })
  }

  _showSendBeneficialDataModal = () => {
    this.setState({
      sendBeneficialDataModalOpen: true,
      openModalData: {},
    })
  }

  _showFundingHoldAccountModal = () => {
    this.setState({ fundingHoldModalOpen: true, })
  }
  _submitUpdateFundingHold = (data) => {
    this.props.submitUpdateFundingHold(data)
  }

  _showRevertProcessorModal = () => {
    this.setState({ revertProcessorModalOpen: true, })
  }

  _showViewMerchantHashModal = () => {
    this.setState({ viewMerchantHashModalOpen: true })
  }

  _closeViewMerchantHashModal = () => {
    this.setState({ viewMerchantHashModalOpen: false })
  };

  _submitRevertProcessor = (data) => {
    this.props.submitRevertProcessor(data)
  }
  _getRatesOptions = (processor = null) => {
    const processorName = parseInt(processor, 10) === 2 ? 'ProPay' : 'Stripe'
    if (this.props.rates) {
      const rateOptions = this.props.rates.filter((rate) =>
        rate.processor === processorName && rate.partners.includes(this.props.merchant.partner)
      ) || []
      return rateOptions.map((r) => ({
        label: r.rateName,
        value: r.id.toString(),
      }))
    }
    return []
  }

  _showTOSAcceptModal = () => {
    this.setState({ acceptTOSModalOpen: true, })
  }
  _submitTOSAcceptance = () => {
    this.props.submitTOSAcceptance()
  }

  _handleActionsClick = (event) => { this.setState({ showMenuDropdown: event.currentTarget, }) }
  _handleActionsClose = () => { this.setState({ showMenuDropdown: null, }) }

  render() {
    const {
      merchant,
      merchantStatus,
      isSubmitting,
    } = this.props
    const {
      canPlaceMerchantFundingHold,
      canReleaseMerchantFundingHold,
      canChangeMerchantStatus,
      canRevertProcessor,
      canEditMerchantAdvancedInfo,
      canViewMerchantHash,
      isRevertingProcessor,
      showMenuDropdown,
    } = this.state
    const currentStatus = _.find(MERCHANT_STATUSES, { status: merchantStatus, })
    const isStripe = merchant.processorName === 'Stripe'
    const processorCanRevert = merchant.processorCanRevert !== 0
    const fundingHold = merchant.merchantBalance ? merchant.merchantBalance : ''
    const oldProcessorGatewayId = merchant.oldProcessorGatewayId
    const oldProcessorGatewayAltId = merchant.oldProcessorGatewayAltId
    const oldProcessorId = merchant.oldProcessorId
    const isBusiness = merchant.businessType === 'NP' || merchant.businessType === 'O'
    const countryCode = merchant.countryId === 'USA' ? 'US' : merchant.countryId
    const baseTier = `${countryCode} ${isBusiness ? 'Corporate' : 'Standard'}`
    const RevertModalRateOptions = this._getRatesOptions(oldProcessorId)

    const showBeneficialModal = () => {
      if (
        merchant.processorName === 'ProPay' &&
        merchant.businessType !== 'NP' &&
        merchant.contacts &&
        merchant.contacts.owners &&
        merchant.contacts.owners.length > 0 &&
        merchant.contacts.owners[0].first_name &&
        merchant.contacts.owners[0].last_name &&
        merchant.contacts.owners[0].date_of_birth &&
        merchant.contacts.owners[0].phone &&
        merchant.contacts.owners[0].ssn_encrypted
      ) {
        return true
      }
      return false
    }

    return (
      <div className='merchant-menu'>
        <div className='quick-actions-icon'><MoreVertIcon onClick={this._handleActionsClick} /></div>
        <Menu
          className='merchant-dropdown-menu'
          anchorEl={ showMenuDropdown }
          keepMounted
          open={Boolean(showMenuDropdown)}
          onClose={this._handleActionsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          { currentStatus && isStripe && (
            (currentStatus.status === 1 && (canReleaseMerchantFundingHold || canPlaceMerchantFundingHold)) ||
              (currentStatus.status === 4 && canReleaseMerchantFundingHold)
          ) && (
              <MenuItem className='item-list-funding-hold'>
                <a
                  onClick={() => {
                    this._handleActionsClose()
                    this._showFundingHoldAccountModal()
                    return false
                  }}
                  disabled={isSubmitting}
                  className='quick-action-funding-hold'
                  title='Place this merchant on a funding hold'
                >
                  {merchant.hasFundingHold ? 'Release Hold' : 'Funding Hold'}
                </a>
              </MenuItem>
            )}
          {canChangeMerchantStatus &&
            <MenuItem
            >
              <a
                onClick={() => {
                  this._handleActionsClose()
                  this._showChangeStatusModal()
                  return false
                }}
                disabled={isSubmitting}
                className='quick-action-close'
                title='Request this merchant to be closed'
              >
                {(!isSubmitting) ? 'Change Status' : 'Saving'}
              </a>
            </MenuItem>
          }
          { (canRevertProcessor && processorCanRevert) &&
            <MenuItem>
              <a
                onClick={() => {
                  this._handleActionsClose()
                  this._showRevertProcessorModal()
                  return false
                }}
                disabled={isSubmitting}
                title={`Revert this merchant to it's previous processor`}
              >
                Revert Processor
              </a>
            </MenuItem>
          }
          { isStripe &&
            <MenuItem>
              <a
                onClick={() => {
                  this._handleActionsClose()
                  this._showTOSAcceptModal()
                  return false
                }}
                disabled={isSubmitting}
                title={`Accept TOS of the account in stripe`}
              >
                T.O.S Update
              </a>
            </MenuItem>
          }
          <MenuItem
            onClick={() => {
              this.props.router.replace(`/admin/reports/transaction-details?merchantid=${merchant.id}`)
            }}>
            Go To Transaction Detail</MenuItem>
          <MenuItem
            onClick={() => {
              this.props.router.replace(`/admin/reports/payouts?merchantid=${merchant.id}`)
            }}>
            Go To Payouts</MenuItem>
          <MenuItem
            onClick={() => {
              this.props.router.replace(`/admin/reports/disputes?merchantid=${merchant.id}`)
            }}>
            Go To Disputes</MenuItem>
          {canEditMerchantAdvancedInfo && showBeneficialModal() &&
            <MenuItem
            >
              <a
                onClick={() => {
                  this._handleActionsClose()
                  this._showSendBeneficialDataModal()
                  return false
                }}
                disabled={isSubmitting}
                className='quick-action-close'
                title='Request this merchant to be closed'
              >
                {(!isSubmitting) ? 'Send Beneficial Data' : 'Sending'}
              </a>
            </MenuItem>
          }
          {canViewMerchantHash && <MenuItem>
            <a
              onClick={() => {
                this._handleActionsClose()
                this._showViewMerchantHashModal()
                return false
              }}
              disabled={isSubmitting}
              title="View the merchant's hash details"
            >
              View Merchant Hash
            </a>
          </MenuItem>
          }
        </Menu>

        <ChangeStatusModal
          open={this.state.changeStatusModalOpen}
          onSubmit={this._handleStatusChange}
          handleClose={() => {
            this.setState({
              changeStatusModalOpen: false,
            })
          }}
          defaultMerchantIds={merchant.id}
          merchantStatus={merchantStatus}
        />
        <ViewMerchantHashModal
          merchantHash={this.props.merchantHash}
          open={this.state.viewMerchantHashModalOpen}
          handleClose={this._closeViewMerchantHashModal}
        />
        {showBeneficialModal() && <SendBeneficialDataModal
          open={this.state.sendBeneficialDataModalOpen}
          onSubmit={this._handleSendBeneficialData}
          handleClose={() => {
            this.setState({
              sendBeneficialDataModalOpen: false,
            })
          }}
          defaultMerchantIds={merchant.id}
          merchant={merchant}
          beneficialData={this.props.beneficialData}
          error={this.props.error}
          isSubmitting={this.props.isSubmitting}
        />}
        <FundingHoldModal
          open={this.state.fundingHoldModalOpen}
          handleClose={() => this.setState({ fundingHoldModalOpen: false, })}
          onSubmit={this._submitUpdateFundingHold}
          hasFundingHold={merchant.hasFundingHold}
          balance={fundingHold.availableBalance}
        />
        <RevertProcessorModal
          open={this.state.revertProcessorModalOpen}
          handleClose={() => this.setState({ revertProcessorModalOpen: false, })}
          onSubmit={this._submitRevertProcessor}
          oldProcessorGatewayId={oldProcessorGatewayId}
          oldProcessorGatewayAltId={oldProcessorGatewayAltId}
          oldProcessorId={oldProcessorId}
          baseTier={baseTier}
          rateOptions={RevertModalRateOptions}
          isRevertingProcessor={isRevertingProcessor}
          // merchantGatewayStatus={merchantGatewayStatus}
          // isFetchingMerchantGatewayStatus={isFetchingMerchantGatewayStatus}
        />
        <TOSAcceptModal
          open={this.state.acceptTOSModalOpen}
          handleClose={() => this.setState({ acceptTOSModalOpen: false, })}
          onSubmit={this._submitTOSAcceptance}
        />
      </div>
    )
  }
}

export default withRouter(MerchantMenu)
