import Immutable from 'seamless-immutable'
import { MerchantActionTypes, } from './actions'

const initialState = Immutable({
  isFetching: true,
  isSubmitting: false,
  isFetchingRatesList: false,
  showSuccessModal: false,
  accountUpdated: false,
  merchantProfile: {},
  beneficialData: {},
  merchantHash: {},
  fullRatesList: {},
  merchantAlerts: {},
  isFetchingMerchantAlerts: true,
  isUploadingFile: false,
  uploadedFilesStore: {},
  localFileIdentifier: null,
  error: null,
  isRemovingFile: false,
})

const ACTION_HANDLERS = {
  [MerchantActionTypes.FETCH_MERCHANT_PROFILE]: (state) => state.merge({
    isFetching: true,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_PROFILE_SUCCESS]: (state, { data, }) => state.merge({
    isFetching: false,
    merchantProfile: data.data,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_PROFILE_ERROR]: (state, { error, }) => state.merge({
    isFetching: false,
    error,
  }),


  [MerchantActionTypes.FETCH_FULL_RATES_LIST]: (state) => state.merge({
    isFetchingRatesList: true,
    fullRatesList: null,
  }),

  [MerchantActionTypes.FETCH_FULL_RATES_LIST_SUCCESS]: (state, { data, }) => state.merge({
    isFetchingRatesList: false,
    fullRatesList: data.data,
  }),


  [MerchantActionTypes.FETCH_FULL_RATES_LIST_ERROR]: (state, { error, }) => state.merge({
    isFetchingRatesList: false,
    error,
  }),

  [MerchantActionTypes.UPDATE_MERCHANT_STATUS]: (state, { merchantId, merchantStatus, closureReason, }) => state.merge({
    isSubmitting: true,
    accountUpdated: false,
  }),

  [MerchantActionTypes.UPDATE_MERCHANT_STATUS_SUCCESS]: (state) => state.merge({
    isSubmitting: false,
    accountUpdated: true,
  }),

  [MerchantActionTypes.UPDATE_MERCHANT_STATUS_SUCCESS_NO_RELOAD]: (state) => state.merge({
    isSubmitting: false,
    accountUpdated: false,
  }),

  [MerchantActionTypes.UPDATE_MERCHANT_STATUS_ERROR]: (state, { error, notification, }) => state.merge({
    isSubmitting: false,
    accountUpdated: false,
    error,
    notification,
  }),

  [MerchantActionTypes.SEND_BENEFICIAL_DATA]: (state, { merchantId, data }) => state.merge({
    isSubmitting: true,
  }),

  [MerchantActionTypes.SEND_BENEFICIAL_DATA_SUCCESS]: (state, { data, }) => state.merge({
    isSubmitting: false,
    beneficialData: data,
  }),

  [MerchantActionTypes.SEND_BENEFICIAL_DATA_ERROR]: (state, { error, }) => state.merge({
    isSubmitting: false,
    error,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_HASH]: (state, { merchantId }) => state.merge({
    isSubmitting: true,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_HASH_SUCCESS]: (state, { data, }) => state.merge({
    isSubmitting: false,
    merchantHash: data,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_HASH_ERROR]: (state, { error, }) => state.merge({
    isSubmitting: false,
    error,
  }),

  //
  // [MerchantActionTypes.FETCH_MERCHANT_GATEWAY_STATUS]: (state) => state.merge({
  //   isFetchingMerchantGatewayStatus: true,
  // }),
  //
  // [MerchantActionTypes.FETCH_MERCHANT_GATEWAY_STATUS_SUCCESS]: (state, { data, }) => state.merge({
  //   isFetchingMerchantGatewayStatus: false,
  //   merchantGatewayStatus: data.status,
  // }),
  //
  // [MerchantActionTypes.FETCH_MERCHANT_GATEWAY_STATUS_ERROR]: (state, { error, }) => state.merge({
  //   isFetchingMerchantGatewayStatus: false,
  //   error,
  // }),

  [MerchantActionTypes.REVERT_PROCESSOR]: (state) => state.merge({
    isRevertingProcessor: true,
    accountUpdated: false,
  }),

  [MerchantActionTypes.REVERT_PROCESSOR_SUCCESS]: (state, { data, }) => state.merge({
    isRevertingProcessor: false,
    accountUpdated: true,
  }),

  [MerchantActionTypes.REVERT_PROCESSOR_ERROR]: (state, { error, }) => state.merge({
    isRevertingProcessor: false,
    error,
  }),

  [MerchantActionTypes.ACCEPT_T_O_S]: (state) => state.merge({
    isSubmitting: true,
    accountUpdated: false,
  }),

  [MerchantActionTypes.ACCEPT_T_O_S_SUCCESS]: (state) => state.merge({
    isSubmitting: false,
    accountUpdated: true,
  }),

  [MerchantActionTypes.ACCEPT_T_O_S_ERROR]: (state, { error, }) => state.merge({
    isSubmitting: false,
    error,
  }),

  [MerchantActionTypes.UPDATE_FUNDING_HOLD]: (state, { merchantId, data, }) => state.merge({
    isSubmitting: true,
    accountUpdated: false,
  }),

  [MerchantActionTypes.UPDATE_FUNDING_HOLD_SUCCESS]: (state) => state.merge({
    isSubmitting: false,
    accountUpdated: true,
    showSuccessModal: 'Merchant updated successfully',
  }),

  [MerchantActionTypes.UPDATE_FUNDING_HOLD_ERROR]: (state, { error, notification, }) => state.merge({
    isSubmitting: false,
    accountUpdated: false,
    error,
    notification,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_ALERTS]: (state) => state.merge({
    isFetchingMerchantAlerts: true,
    merchantAlerts: null,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_ALERTS_SUCCESS]: (state, { data, }) => state.merge({
    merchantAlerts: data,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_ALERTS_ERROR]: (state, { error, }) => state.merge({
    isFetchingMerchantAlerts: false,
    error,
  }),


  [MerchantActionTypes.UPLOAD_CHECKOUT_FILE]: (state, { file, localFileIdentifier }) => state.merge({
    isUploadingFile: { [localFileIdentifier]: true },
    localFileIdentifier,
  }),

  [MerchantActionTypes.UPLOAD_CHECKOUT_FILE_SUCCESS]: (state, { data, }) => {
    const { localFileIdentifier, uploadedFilesStore } = state
    const uploadedFiles = { ...uploadedFilesStore }
    uploadedFiles[localFileIdentifier] = data.file
    uploadedFiles[localFileIdentifier].progress = 100

    return state.merge({
      isUploadingFile: { [localFileIdentifier]: false },
      uploadedFilesStore: uploadedFiles,
    })
  },

  [MerchantActionTypes.UPLOAD_CHECKOUT_FILE_ERROR]: (state, { error }) => {
    const { localFileIdentifier, uploadedFilesStore } = state

    const uploadedFiles = { ...uploadedFilesStore }
    uploadedFiles[localFileIdentifier] = { ...uploadedFiles[localFileIdentifier], error: error.notify.message }

    return state.merge({
      isUploadingFile: { [localFileIdentifier]: false },
      uploadedFilesStore: uploadedFiles,
      error
    })
  },

  [MerchantActionTypes.REMOVE_CHECKOUT_FILE]: (state, { fileId, fileType }) => state.merge({
    isRemovingFile: true,
  }),

  [MerchantActionTypes.REMOVE_CHECKOUT_FILE_SUCCESS]: (state, { data }) => {
    const fileType = data.fileType
    return state.merge({
      isRemovingFile: false,
      uploadedFilesStore: { ...state.uploadedFilesStore, [fileType]: { deleted: true } }
    })
  },

  [MerchantActionTypes.REMOVE_CHECKOUT_FILE_ERROR]: (state, { error, }) => state.merge({
    isRemovingFile: false,
    error,
  }),

  [MerchantActionTypes.UPDATE_UPLOADED_FILES_STORE]: (state, { data, fileType }) => {
    const uploadedFiles = { ...state.uploadedFilesStore }
    uploadedFiles[fileType] = data

    return state.merge({
      uploadedFilesStore: uploadedFiles,
    })
  },
  [MerchantActionTypes.RESET_UPLOADED_FILES_STORE]: (state) => state.merge({
    uploadedFilesStore: {},
  }),

  [MerchantActionTypes.REMOVE_EXTERNAL_FILE]: (state, { fileType }) => {
    const uploadedFiles = { ...state.uploadedFilesStore }
    delete uploadedFiles[fileType]

    return state.merge({
      uploadedFilesStore: uploadedFiles,
    })
  }
}

export default function applicationFormReducer(state = initialState, action) {
  try {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
  } catch (e) {
    return e
  }
}
