import React from 'react'
import { Link } from 'react-router'
import './ReportLink.scss'

const ReportLink = ({ url, stateParams = {}, icon, label, onClick = () => {} }) => {
  const linkTo = {
    pathname: url,
    state: stateParams,
  }

  return (
    <Link to={linkTo} onClick={onClick} className='dashboard-report-link'>
      <span className='icon'>
        <span className='material-icons'>{icon || 'analytics'}</span>
      </span>
      <span className='label'>{label || 'View Report'}</span>
    </Link>
  )
}

export default ReportLink
